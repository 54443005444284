<template>
  <v-row>
    <v-col>
      <v-card width="100%">
        <v-tabs>
          <v-tab>Member Verification</v-tab>
          <v-tab>Verified Members</v-tab>
          <v-tab>Member Links</v-tab>
          <v-tab>Member Stocks</v-tab>

          <v-tab-item>
            <v-card-text>
              <VerifyList />
            </v-card-text>
          </v-tab-item>

          <v-tab-item>
            <v-card-text>
              <Form />
              <VerifiedMembers />
            </v-card-text>
          </v-tab-item>

          <v-tab-item>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <LinkForm/>
                </v-col>
                <v-col cols="8">
                  <LinkList/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>

          <v-tab-item>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <StockForm/>
                </v-col>
                <v-col cols="8">
                  <StockList/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  components: {
    Form: () => import("@/components/member/Form.vue"),
    StockForm: () => import("@/components/member/stocks/Form.vue"),
    StockList: () => import("@/components/member/stocks/List.vue"),
    LinkForm: () => import("@/components/member/links/Form.vue"),
    LinkList: () => import("@/components/member/links/List.vue"),
    VerifyList: () => import("@/components/member/VerifyList.vue"),
    VerifiedMembers: () => import("@/components/member/VerifiedMembers.vue"),
  },
  methods: {
    ...mapActions(["fetchMembers"]),
  },
  mounted() {
    this.fetchMembers();
  },
};
</script>